<template>
  <div class="product-mapper-block" v-if="showBlock">
    <v-overlay>

      <v-card class="content" v-if="showLoadingData">
        <div class="loading-box">
          <v-card-title>
            <v-img class="rotate" src="~@/assets/images/logo.svg" height="50px" contain></v-img>
          </v-card-title>
          <v-card-text class="text-center">
            Initiating product mapper...
          </v-card-text>
        </div>
      </v-card>

      <v-card class="content" v-else v-click-outside="closeDialog" width="1000px">
        <v-toolbar @click="closeDialog()" color="white" class="text--primary top-bar fixed-bar" flat>
          <v-icon class="icon text--primary mr-2">keyboard_arrow_left</v-icon>Back to product list
        </v-toolbar>
        <div class="upper-row">
          <v-row>
            <v-col cols="8">
              <v-card-title>
                <v-icon color="orange" large class="mr-3">link</v-icon>
                <h2>Map with Existing</h2>
              </v-card-title>
            </v-col>
            <v-col cols="4 text-right">
              <v-btn v-if="enableMapping" large color="primary" class="establish-link-btn" @click="mapProducts()">Map</v-btn>
            </v-col>
          </v-row>
        </div>

        <div class="search-shop-bar">
          <v-row>
            <v-col cols="6 left">
              <v-card light outlined class="shop-info-wrapper overflow-hidden">
                <v-card-text class="info-block">
                  <div class="from-label float-left">
                    FROM
                  </div>
                  <div class="float-left shop">
                    <v-img
                      width="25"
                      v-if="currentPartnerShop.platform === 'shopify'"
                      src="@/assets/images/shopify_logo.png"
                      class=""
                    ></v-img>
                    <v-img
                      width="30"
                      v-if="currentPartnerShop.platform === 'woocommerce'"
                      src="@/assets/images/woo_logo.png"
                    ></v-img>
                    <div class="shop-name">
                      <h3 class="h4">{{ currentPartnerShop.store_domain }}</h3>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6 right">
              <v-card light outlined class="shop-info-wrapper overflow-hidden">
                <v-card-text class="info-block">
                  <div class="to-label float-left">
                    TO
                  </div>
                  <div class="float-left shop">
                    <v-img
                      width="25"
                      v-if="currentShop.platform === 'shopify'"
                      src="@/assets/images/shopify_logo.png"
                    ></v-img>
                    <v-img
                      width="30"
                      v-if="currentShop.platform === 'woocommerce'"
                      src="@/assets/images/woo_logo.png"
                    ></v-img>
                    <div class="shop-name">
                      <h3 class="h4">{{ currentShop.store_domain }}</h3>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-autocomplete
                :items="matchingProducts"
                :loading="searchingMatchingProducts"
                :no-filter="true"
                :search-input.sync="search"
                attach=".autocomplete-input"
                class="mt-3 autocomplete-input"
                dense
                hide-no-data
                item-text="title"
                item-value="ref_id"
                label="Search using Title or Product ID"
                light
                outlined
                prepend-inner-icon="search"
                v-model="selectedProductId"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </div>

        <div class="products-holding-bar">

          <v-row>

            <v-col cols="6 left">
              <product-summary-component v-if="currentViewShopProduct != null" :display-product="currentViewShopProduct.sourceProduct"></product-summary-component>
            </v-col>

            <v-col cols="6 right">
              <v-card
                v-if="searchingMatchingProducts && !loadingDestinationProduct"
                light
                outlined
                class="searching-matching-products-block"
              >
                <div class="loading-box no-product-block">
                  <v-card-title>
                    <v-img
                      class="rotate"
                      src="~@/assets/images/logo.svg"
                      height="50px"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-text class="text-center">
                    Searching Matching Proudcts...
                  </v-card-text>
                </div>
              </v-card>
              <v-card
                v-else-if="
                  !searchingMatchingProducts && loadingDestinationProduct
                "
                light
                outlined
                class="loading-destination-product-block"
              >
                <div class="loading-box no-product-block">
                  <v-card-title>
                    <v-img
                      class="rotate"
                      src="~@/assets/images/logo.svg"
                      height="50px"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-text class="text-center">
                    Loading Product...
                  </v-card-text>
                </div>
              </v-card>
              <div v-else class="no-product-block">

                <product-summary-component v-if="destinationProduct != null" :display-product="destinationProduct"></product-summary-component>
                <product-summary-component v-else-if="currentViewShopProduct.destinationProduct != null && destinationProduct === null" :display-product="currentViewShopProduct.destinationProduct"></product-summary-component>

                <v-card v-else light outlined class="no-product-block"></v-card>
              </div>
            </v-col>
          </v-row>
        </div>

        <v-overlay :value="isSyncLimitModalVisible" class="dialog dialog-disconnect">
          <v-card light class="card-rounded not-a-link" width="550px" v-click-outside="closeSyncLimitModalHandler">
            <v-toolbar height="71">
              <v-container class="px-0 d-flex justify-space-between">
                <v-card-title class="h3 pa-0">
                  Selection exceeds your plan limit
                </v-card-title>
                <v-icon size="30px" color="#ffffff" class="mt-1" @click="closeSyncLimitModalHandler">close</v-icon>
              </v-container>
            </v-toolbar>

            <div class="dialog-body pa-10">
              <h2 class="mb-4" v-if="productsSyncedLimit === productsSynced">
                Maximum products sync limit reached.
              </h2>
              <h2 class="mb-4" v-else>
                You can only sync {{ productsSyncedLimit - productsSynced }} more products.
              </h2>

              <p>Upgrade your syncio plan to sync above your current plan's limit or, Unsync some products to free up your plan limit.</p>

              <div class="d-flex justify-space-between align-center mt-8">
                <router-link tag="button" v-if="currentShop && currentShop.platform === 'shopify'" to="/account/billing" class="upgrade-btn" style="height: 48px; width: 152px; font-weight: bold;">Upgrade</router-link>
                <a v-if="currentShop && currentShop.platform === 'woocommerce'" :href="wooPlanSelectionLink" class="upgrade-btn mt-5" style="height: 48px; width: 152px; line-height: 48px; font-weight: bold;">Upgrade</a>
              </div>
            </div>

          </v-card>
        </v-overlay>
      </v-card>
    </v-overlay>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { ADD_NOTIFICATION } from "@/store/actions.type";

import ShopApiService from "@/common/api/shop.service";
import ProductApiService from "@/common/api/product.service";
import ProductSummaryComponent from "@/views/product/components/ProductSummaryComponent";
import _ from "lodash";
import JwtService from "@/common/jwt.service";

import {
  SET_CURRENT_PARTNER_SHOP,
  SET_IS_LOADING_PRODUCTS_BY_SHOP,
  SET_SHOW_PRODUCT_MAPPER,
  SET_CURRENT_VIEW_SHOP_PRODUCT,
} from "@/store/mutations.type";

export default {
  name: "ProductMapperBlock",
  data: function() {
    return {
      selectedProductId: null,
      destinationProduct: null,
      matchingProducts: [],
      search: null,
      searchingMatchingProducts: false,
      loadingDestinationProduct: false,
      isMappingProducts: false,
      isSyncLimitModalVisible: false
    };
  },
  props: ["partnerShopId", "enableMapping", "productsSynced", "productsSyncedLimit"],
  components: {
    ProductSummaryComponent,
  },
  created: function() {
    this.debouncedSearchProducts = _.debounce(this.searchProducts, 500);
  },
  computed: {
    ...mapState("shop", ["currentShop"]),
    ...mapState("product", [
      "showProductMapper",
      "currentViewShopProduct",
      "currentPartnerShop",
    ]),
    showBlock() {
      return this.showProductMapper && this.currentViewShopProduct != null;
    },
    showLoadingData() {
      return this.currentPartnerShop === null;
    },
    wooPlanSelectionLink() {
      return `${ process.env.VUE_APP_WOO_BILLING}/?jwt=${JwtService.getToken() }`
    },
  },
  watch: {
    search(val) {
      this.debouncedSearchProducts();
    },
    selectedProductId(newId, oldId) {
      if (newId != oldId && newId != null) {
        this.getDestinationProduct();
      }
    },
  },
  mounted() {
    this.$store.commit(`product/${SET_CURRENT_PARTNER_SHOP}`, null);
    if (this.currentViewShopProduct != null && this.partnerShopId != null) {
      ShopApiService.getShop(this.partnerShopId)
        .then(({ data }) => {
          if (data.success) {
            this.$store.commit(
              `product/${SET_CURRENT_PARTNER_SHOP}`,
              data.store
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
      this.$store.commit(`product/${SET_SHOW_PRODUCT_MAPPER}`, false);
      this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, null);
      this.$store.commit(`product/${SET_CURRENT_PARTNER_SHOP}`, null);
      document.querySelector('.intercom-lightweight-app, #intercom-container').style.display = 'initial';
    },
    searchProducts() {
      if (this.search != null && this.search.length > 3) {
        this.searchingMatchingProducts = true;
        new Promise((resolve) => {
          ProductApiService.searchProduct(this.currentShop.id, this.search)
            .then(({ data }) => {
              if (data.success && data.products.length > 0 && !!data.products[0]) {
                this.matchingProducts = data.products;
              }
              this.searchingMatchingProducts = false;
              resolve();
            })
            .catch((error) => {
              console.log(error);
              this.searchingMatchingProducts = false;
            });
        });
      }
    },
    getDestinationProduct() {
      this.loadingDestinationProduct = true;
      new Promise((resolve) => {
        ProductApiService.loadProductOnShop(
          this.currentShop.id,
          null,
          this.selectedProductId
        )
          .then(({ data }) => {
            if (data.success) {
              this.destinationProduct = data.sourceProduct;
            }
            this.loadingDestinationProduct = false;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            this.loadingDestinationProduct = false;
          });
      });
    },
    mapProducts() {
      let productsAvailableToSync = +this.productsSyncedLimit - +this.productsSynced;

      if(productsAvailableToSync === 0) {
        this.isSyncLimitModalVisible = true;
        return;
      }

      this.isMappingProducts = true;
      new Promise((resolve) => {
        let destinationProductId = null;
        if(this.destinationProduct && this.destinationProduct.ref_id) {
          destinationProductId = this.destinationProduct.ref_id;
        } else if(this.currentViewShopProduct && this.currentViewShopProduct.destinationProduct) {
          destinationProductId = this.currentViewShopProduct.destinationProduct.ref_id;
        }
        ProductApiService.mapProducts(
          this.partnerShopId,
          this.currentShop.id,
          this.currentViewShopProduct.sourceProduct.ref_id,
          destinationProductId
        )
          .then(({ data }) => {
            if (data.success) {
              this.$store.dispatch(
                `notification/${ADD_NOTIFICATION}`,
                {
                  notification: {
                    id: "N" + Math.floor(Math.random() * 100000000),
                    position: "top-left",
                    type: "info",
                    body:
                      "Product (" +
                      this.currentViewShopProduct.title +
                      ") successfully linked.",
                    state: 0,
                    length: 6000, // seconds
                    data: null,
                  },
                },
                { root: true }
              );
            } else {
              this.$store.dispatch(
                `notification/${ADD_NOTIFICATION}`,
                {
                  notification: {
                    id: "N" + Math.floor(Math.random() * 100000000),
                    position: "top-right",
                    type: "error",
                    body: data.errors[0],
                    state: 0,
                    length: 6000, // seconds
                    data: null,
                  },
                },
                { root: true }
              );
            }

            this.isMappingProducts = false;
            resolve();
          })
          .catch((error) => {
            console.lgo(error);
            this.isMappingProducts = false;
          });
      });
    },

    closeSyncLimitModalHandler() {
      this.isSyncLimitModalVisible = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.product-mapper-block {
  .content {
    border-radius: 0px;
    position: fixed;
    overflow-y: scroll;
    background-color: #fff;
    color: #333;
    height: 100%;
    top: 0;
    right: 0;
    min-width: 800px;
    max-width: 1000px;
    .loading-box {
      margin: auto;
    }
    .row {
      margin: 0px;
    }
    .establish-link-btn {
      padding: 10px 50px;
    }
    .products-holding-bar,
    .search-shop-bar {
      position: relative;
      .left {
        padding-right: 40px;
      }
      .right {
        padding-left: 40px;
      }
      .shop-info-wrapper {
        .info-block {
          padding: 0px;
          overflow: hidden;
          .to-label,
          .from-label {
            width: 60px;
            text-align: center;
            background-color: #cfd8dc;
            line-height: 50px;
          }
          .to-label {
            background-color: #fff3e0;
          }
          .shop {
            padding: 10px 20px 10px 30px;
            box-sizing: border-box;
            .v-image {
              float: left;
            }
            .shop-name {
              float: left;
              padding-left: 15px;
              line-height: 29px;
            }
          }
        }
      }
    }
    .search-shop-bar {
      z-index: 1;

      .theme--dark.v-sheet {
        background: transparent !important;
        display: none;
      }
    }
    .products-holding-bar {
      .no-product-block {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>
