<template>
  <div class="product-counter-wrapper">
    <div class="product-counter">
      <div class="fa" v-if="totalProductCounter === null">
        <v-icon class="loading_inventory">
          rotate_right
        </v-icon>
      </div>
      <span v-else>{{ totalProductCounter }}</span>
    </div>
  </div>
</template>

<script>
import ProductApiService from '@/common/api/product.service';

export default {
  name: "SourceStoreProductInventoryCounter",
  props: ['sourceStoreId', 'productId', 'locationId', 'destinationStoreId', 'timeout', 'index', 'inventoryItemIndex'],
  data: function() {
    return {
      totalProductCounter: null,
      apiRetry: 0
    }
  },
  mounted() {
    setTimeout(this.counterCall, +this.computedIndex);
  },
  computed: {
    computedIndex() {
      if(this.index > 25) {
        return (+this.index*1000) - (this.inventoryItemIndex*1000);
      }
      return this.index*1000;
    }
  },
  methods: {
    counterCall() {
      ProductApiService.loadProductInventoryByLocation(
        this.sourceStoreId,
        this.productId,
        this.locationId
      ).then(({ data }) => {
        if (data.success) {
          this.totalProductCounter = data.inventory_quantity;
        }
      }).catch((error) => {
        if ((error.response.status === 429 || error.response.status === 500) && this.apiRetry < 4){
          setTimeout( this.counterCall, 2000 * this.apiRetry);
          this.apiRetry = this.apiRetry + 1;
        }
      }).finally(() => {
        this.$emit('inventoryUpdate', this.index);
      });
    }
  }
}
</script>

<style scoped>
.product-counter {
  font-size: 12px;
}
.loading_inventory {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(-359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
