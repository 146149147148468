<template>
<div class="product-summary-wrapper">
    <v-card light outlined>
        <v-card-title class="pb-0">
            <div class="product-desc pr-5">
                <h2 class="mb-3">{{ displayProduct.title }}</h2>
                <div>Product ID: {{ displayProduct.ref_id }}</div>
                <template v-if="currentShop.platform === 'shopify'">
                    <div v-if="displayProduct.meta_fields && displayProduct.meta_fields.vendor">Vendor: {{ displayProduct.meta_fields.vendor }}</div>
                    <div v-if="displayProduct.meta_fields && displayProduct.meta_fields.product_type">Product Type: {{ displayProduct.meta_fields.product_type }}</div>
                </template>
                <template v-else>
                    <div v-if="displayProduct && displayProduct.vendor">Vendor: {{ displayProduct.vendor }}</div>
                    <div v-if="displayProduct && displayProduct.type">Product Type: {{ displayProduct.type }}</div>
                </template>
            </div>
            <v-img :src="displayProduct.image"></v-img>
        </v-card-title>

        <v-card-title class="pt-0">
            <div v-if="displayProduct.tags.length > 0">
                <span @click="areTagsVisible = !areTagsVisible" class="pointer">
                    <span class="material-icons tag-icon" v-if="!areTagsVisible">chevron_right</span>
                    <span class="material-icons tag-icon" v-else>expand_more</span>
                    Tags
                </span>
                <br />
                <template v-if="areTagsVisible">
                    <v-chip v-for="(tag, index) in displayProduct.tags" :key="index" small class="mr-1" color="primary">
                        <v-icon x-small class="mr-1">local_offer</v-icon>
                        {{ tag }}
                    </v-chip>
                </template>
            </div>
            <div v-else>
                Tags are not available
            </div>
        </v-card-title>

        <div class="variants-list">
            <table class="variants-table" CELLSPACING="0">
                <thead>
                    <tr class="text-left">
                        <th>TITLE</th>
                        <th><span class="text-uppercase">{{ currentShop.sync_key }}</span></th>
                        <th class="text-right">QUANTITY</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(variant, index) in displayProduct.variants" :key="index">
                        <td class="vtitle">{{ variant.title }}</td>
                        <td class="vsku">{{ variant[currentShop.sync_key] }}</td>
                        <td class="vqty text-right">{{ variant.inventory_quantity }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </v-card>
</div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "ProductSummaryComponent",
    data() {
        return {
            areTagsVisible: false,
        }
    },
    props: ["displayProduct"],
    computed: {
        ...mapState("shop", ["currentShop"]),
    }
}
</script>
<style lang="scss" scoped>
.product-summary-wrapper {
    .product-desc {
        width: 60%;
        float: left
    }
    .v-image {
        width: 40%;
        align-self: flex-start;
    }
    .tag-icon {
        vertical-align: middle;
    }
    .variants-list {
            padding: 10px 32px;
            margin-top: 15px;
            .variants-table {
                width: 100%;
                max-width: 600px;
                th {
                    border-bottom: 1px solid #333;
                }
                .vtitle {
                    height: 30px;
                }
            }
        }
}
</style>