<template>
  <v-container class="pagination-wrapper pl-0 pr-0 pb-0">
    <v-card v-if="!isLoading" elevation="0">
      <v-row>
        <v-col cols="6">
          <span class="px-4 range-display" v-if="productPaginate.total > 0">
            {{ range }}
            <v-menu offset-y content-class="range-display-menu" v-if="productPaginate.total > 25">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark v-bind="attrs" v-on="on">
                  <v-icon class="range-dropdown" size="25">arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item :class="{'selected': selectedPerPage === item }" v-for="item in perPageRange" :key="item" @click="selectRangeHandler(item)">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <span>of</span>
            {{ productPaginate.total }}
            <span>Product(s)</span>
          </span>
        </v-col>
        <v-col cols="6">
          <v-btn-toggle class="float-right mr-5" v-model="currentPage">
            <v-btn small :disabled="currentPage < 2" @click="paginatePage(1)">
              <span class="material-icons">
                first_page
              </span>
            </v-btn>
            <v-btn small :disabled="currentPage < 2" @click="paginatePage(currentPage - 1)">
              <span class="material-icons">
                keyboard_arrow_left
              </span>
            </v-btn>

            <v-select
              :items="totalPages"
              :label="String(currentPage + ' of ' + totalPages.length) || '1'"
              :menu-props="{ top: true }"
              @change="paginatePageUsingDropDown($event)"
              attach=".pagination-dropdown"
              class="pagination-dropdown"
              dense
              solo
              v-if="totalPages.length > 1" elevation="0">
            </v-select>

            <v-btn v-else small :disabled="true">
              <span class="current-page-text">{{ currentPage}}</span>
            </v-btn>
            <v-btn small :disabled="currentPage == productPaginate.last_page" @click="paginatePage(currentPage + 1)">
              <span class="material-icons">
                keyboard_arrow_right
              </span>
            </v-btn>
            <v-btn small :disabled="currentPage == productPaginate.last_page" @click="paginatePage(productPaginate.last_page)">
              <span class="material-icons">
                last_page
              </span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { SET_PRODUCT_PAGEINATE_CURRENT_PAGE } from "@/store/mutations.type";
import { FETCH_PRODUCTS_BY_URL } from "@/store/actions.type";
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'product/getField',
  mutationType: 'product/updateField'
});

export default {
  name: "ProductPagination",
  props: ["searchStr"],
  watch: {
    currentPage: function(newVal, oldVal) {
      if (!(this.productPaginate.search_path != null && newVal != oldVal)) {
        this.paginatePage(newVal);
      }
    },
  },
  computed: {
    ...mapFields([
      'selectedPerPage',
    ]),
    ...mapState('product', ['productPaginate', 'isLoadingProductsByShop', 'isLoadingConnectedShops', 'perPageRange']),
    isLoading() {
      return this.isLoadingProductsByShop || this.isLoadingConnectedShops;
    },
    currentPage: {
      get() {
        return this.productPaginate.current_page;
      },
      set(value) {
        return this.$store.commit(`product/${SET_PRODUCT_PAGEINATE_CURRENT_PAGE}`, value, {
          root: true,
        });
      },
    },
    lastPage: {
      get() {
        return this.productPaginate.last_page;
      },
    },
    totalPages() {
      let pages = Math.ceil(+this.productPaginate.total / +this.selectedPerPage)
      let pagesToDisplay = []
      for (var i=1; i<=pages; i++) {
        pagesToDisplay.push(i);
      }

      return pagesToDisplay;
    },
    range() {
      let index = this.productPaginate.current_page - 1;
      let currentPage = this.productPaginate.current_page;
      let limiter = this.productPaginate.limiter;
      let first = index * this.productPaginate.limiter + 1;
      let last = Number(currentPage) * Number(limiter);
      if (last > this.productPaginate.total || this.productPaginate.total < this.productPaginate.limiter) {
        last = this.productPaginate.total;
      }

      return first + "-" + last;
    },
  },
  methods: {
    paginatePage(pageNumber) {
      this.$emit('pageChanged', []);
      this.$store.dispatch(`product/${FETCH_PRODUCTS_BY_URL}`,
        this.productPaginate.search_path+'&page='+pageNumber+'&limiter='+this.selectedPerPage
      );
    },
    paginatePageUsingDropDown(value) {
      this.paginatePage(value);
    },
    selectRangeHandler(item) {
      this.selectedPerPage = item;
      this.$emit('pageChanged', []);
      this.$store.dispatch(`product/${FETCH_PRODUCTS_BY_URL}`,
        this.productPaginate.search_path+'&page=1'+'&limiter='+this.selectedPerPage
      );
    }
  },
};
</script>

<style lang="scss">
.range-display-menu {
  .v-list-item {
    text-align: center;
    min-height: 36px;
    
    &.selected {
      background: rgba(246, 246, 246, 1);
    }
  }

  .v-list-item__title {
    font-size: 18px;
  }
}

.pagination-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border: {
    left: 0;
  }
  height: 28px;
  width: 100px;

  .v-input__control {
    height: 28px;
    min-height: inherit !important;
    padding-bottom: 0 !important;
    border-radius: 0;

    input {
      padding: 0 !important;
    }
  }

  label,
  .v-icon {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: normal;
    font-size: 13px;
  }

  label {
    font-size: 13px;
    left: 50% !important;
    transform: translateX(-75%);
  }

  .v-icon {
    font-size: 24px;
  }

  .v-input__append-inner {
    padding-left: 0 !important;
  }

  .v-icon {
    transform: translateY(1px);
  }

  .v-input__slot {
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 8px 0 10px !important;
  }

  .v-menu__content {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0;
    border-bottom: 0;
  }
}

.pagination-wrapper {
  .v-btn {
    border-radius: 0 !important;
  }

  bottom: 0;
  position: sticky;

  .range-display {
    display: inline-block;
    font-weight: bold;
    line-height: 0;

    span {
      font-weight: normal;
    }

    .v-btn {
      background: transparent;
      box-shadow: none;
      display: inline-block;
      height: 30px;
      min-width: 25px;
      padding: 0;
      transform: translate(-1px, -1.5px);
      width: 25px;
    }

    .v-btn__content {
      padding: 0;
    }

    .v-icon {
      color: #000;
    }
  }

  .current-page-text {
    color: #333 !important;
  }
}
</style>
