<template>
  <div class="bulk-smart-mapping">
    <v-btn color="primary" elevation="0" class="btn connect-new-btn btn-primary" @click="showBulkMappingDialog = true">
      <v-icon dark class="mr-2" size="16px">
        settings
      </v-icon>
      Bulk Mapper
    </v-btn>
    <v-overlay :value="showBulkMappingDialog">
      <v-card light max-width="600" class="overflow-hidden pa-3 text-left">
        <v-card-title class="text-h2">Bulk Mapper</v-card-title>
        <v-card-text class="pb-2">
          Once you click the start button, Syncio will scan through this source
          store’s products and try to find the products that is matched from
          your store.
        </v-card-text>
        <v-card-text class="text-center pt-0">
          <v-btn
            color="warning"
            outlined
            elevation="0"
            retain-focus-on-click="false"
            >{{ currentPartnerShop.store_domain }}</v-btn
          >
        </v-card-text>
        <v-card-text>
          <div class="text-h4 mb-1">How to use bulk mapper:</div>
          <ul>
            <li>Ensure that each SKU is unique per variant.</li>
            <li>
              Ensure that the SKUs match between the Source and Destination
              stores.
            </li>
            <li>
              When you click "Start", Syncio will search the two stores for SKU
              matches across the products that are not duplicate SKUs to map.
              Once mapped, the product status will update to "Synced" in the
              Sync Products page.
            </li>
          </ul>
        </v-card-text>
        <v-card-actions class="float-right">
          <v-btn elevation="0" outlined @click="showBulkMappingDialog = false"
            >Cancel</v-btn
          >
          <v-btn elevation="0" color="primary" @click="startBulkMapping()">
            Start
          </v-btn>
        </v-card-actions>
      </v-card>
      <data-preparation-overlay v-if="isUpdating"></data-preparation-overlay>
    </v-overlay>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ProductApiService from "@/common/api/product.service";
import DataPreparationOverlay from "../../components/DataPreparationOverlay.vue";
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'product/getField',
  mutationType: 'product/updateField'
});

export default {
  components: { DataPreparationOverlay },
  name: "BulkSmartMappingBtn",
  data: function() {
    return {
      showBulkMappingDialog: false,
      isUpdating: false,
    };
  },
  props: ["productsSyncedLimit", "productsSynced"],
  computed: {
    ...mapState("product", ["selectProductShopId"]),
    ...mapState("shop", ["currentShop", "connectedShops"]),
    currentPartnerShop() {
      let result = null;
      this.connectedShops.forEach((shop) => {
        if (shop.id === this.selectProductShopId) {
          result = shop;
        }
      });
      return result;
    },
    ...mapFields([
      'isSyncLimitModalVisible'
    ]),
  },
  methods: {
    startBulkMapping() {
      let productsAvailableToSync = +this.productsSyncedLimit - +this.productsSynced;

      if(productsAvailableToSync === 0) {
        this.isSyncLimitModalVisible = true;
        return;
      }

      this.isUpdating = true;
      ProductApiService.bulkSmartProductMapping(
        this.currentPartnerShop.id,
        this.currentShop.id
      ).finally(() => {
        this.isUpdating = false;
        this.showBulkMappingDialog = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bulk-smart-mapping {
  .v-btn {
    height: 40px;
    border-radius: 4px !important;
    width: fit-content;
  }
}
</style>