<template>
  <div class="table-control-panel p-3">
    <div class="controls">
      <v-row>
        <v-col cols="10" class="pl-0">
          <div class="float-left mr-2">
            <v-tooltip bottom :disabled="valid">
              <template v-slot:activator="{ on }">
                <div v-on="on" class="d-inline-block">
                  <v-btn
                    :disabled="refreshTimer != 15"
                    @click="searchProducts(true)"
                    color="primary"
                    icon
                    refs="refreshTable"
                    style="transform: translateY(3px)">
                    <v-icon>refresh</v-icon>
                  </v-btn>
                </div>
              </template>
              <span v-if="refreshTimer >= 15">Click to Refresh</span>
              <span v-else>Refresh in {{ refreshTimer }} seconds</span>
            </v-tooltip>
          </div>

          <template v-if="activeShop.total_product_count > 30000 || enableNewFilters">
            <v-row>
              <v-col cols="3" style="padding-right: 15px;">
                <v-select
                  :hide-details="true"
                  :items="searchOptions"
                  :menu-props="{ 'content-class': 'select-menu' }"
                  clearable
                  dense
                  item-text="text"
                  item-value="value"
                  label="Search products by"
                  no-data-text="No options available"
                  outlined
                  @click:clear="resetSearch"
                  @change="searchProductsByAttribute()"
                  v-model="selectedSearchAttribute"
                  ref="searchOptionsDropdown"
                >
                </v-select>
              </v-col>

              <v-col cols="9" class="pr-0" style="padding-left: 8px; position: relative;">
                <v-text-field
                  :hide-details="true"
                  @click:append="resetSearch"
                  :append-icon="!searchStr ? '' : 'clear'"
                  dense
                  outlined
                  label="Your search query"
                  prepend-inner-icon="search"
                  v-model="searchStr"
                  @change="searchProductsByAttribute()"
                  ref="searchTextArea"
                >
                </v-text-field>
                <span v-if=" selectedSearchAttribute === 'none' || !selectedSearchAttribute"
                  @click="$refs.searchOptionsDropdown.isMenuActive = true"
                  class="disabled-cover"
                ></span>
              </v-col>
            </v-row>
          </template>

          <template v-else>
            <v-text-field
              :append-icon="!searchStr ? '' : 'clear'"
              :hide-details="true"
              @change="searchProducts()"
              @click:append="resetSearch"
              dense
              label="Search products by name, ID, tag or SKU"
              outlined
              prepend-inner-icon="search"
              v-model="searchStr"
            >
            </v-text-field>
          </template>
        </v-col>
        <v-col cols="2">
          <v-btn
            outlined
            height="40"
            class="sort-by-button"
            @click="showSortByOptions = !showSortByOptions"
          >
            <span class="mr-3">Sort</span>
            <v-icon>sort</v-icon>
          </v-btn>
          <v-card v-if="showSortByOptions" class="sortby-options" elevation="0">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Alphabetical Order</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-radio-group
                    v-model="sortValue"
                    column
                    @change="searchProducts()"
                  >
                    <v-radio
                      :input-value="active"
                      label="A to Z"
                      value="atoZ"
                    ></v-radio>
                    <v-radio
                      :input-value="active"
                      label="Z to A"
                      value="ztoA"
                    ></v-radio>
                  </v-radio-group>
                </template>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Publish Date</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-radio-group
                    v-model="sortValue"
                    column
                    @change="searchProducts()"
                  >
                    <v-radio
                      :input-value="active"
                      label="Newest to Oldest"
                      value="newToOld"
                    ></v-radio>
                    <v-radio
                      :input-value="active"
                      label="Oldest to Newest"
                      value="oldToNew"
                    ></v-radio>
                  </v-radio-group>
                </template>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-select
            :hide-details="true"
            :items="availabilityOptions"
            :menu-props="{ 'content-class': 'select-menu' }"
            @input="searchProducts()"
            @click:clear="availabilityFilter = null"
            clearable
            dense
            label="Sales Channel Visibility"
            outlined
            v-model="availabilityFilter"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            :hide-details="true"
            :items="productTypeFilterOptions"
            :menu-props="{ 'content-class': 'select-menu' }"
            @input="searchProducts()"
            @click:clear="selectedProductTypeFilter = null"
            clearable
            dense
            item-text="value"
            item-value="value"
            label="Product Type"
            no-data-text="No options available"
            outlined
            v-model="selectedProductTypeFilter"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            :hide-details="true"
            :items="vendorFilterOptions"
            :menu-props="{ 'content-class': 'select-menu' }"
            @change="searchProducts()"
            @click:clear="selectedVendorFilter = null"
            clearable
            dense
            item-text="value"
            item-value="value"
            label="Vendor"
            no-data-text="No options available"
            outlined
            v-model="selectedVendorFilter"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            :hide-details="true"
            :items="availableStatus"
            :menu-props="{ 'content-class': 'select-menu' }"
            @change="searchProducts()"
            @click:clear="statusFilter = null"
            clearable
            dense
            label="Status"
            outlined
            v-model="statusFilter"
          >
          </v-select>
        </v-col>
        <v-col cols="3" class="pt-0">
          <v-switch
            @click="searchProducts()"
            color="blue darken-1"
            dense
            flat
            hide-details
            inset
            label="Hide 0 Stock Products"
            v-model="excludeZeroStock">
          </v-switch>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { SET_CURRENT_SHOP_PRODUCTS } from "@/store/mutations.type";
import { FETCH_PRODUCTS_LIST } from "@/store/actions.type";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "product/getField",
  mutationType: "product/updateField",
});

export default {
  name: "TableControlPanel",
  props: [
    "selectedPerPage",
    "selectedShop",
    "productTypeFilterOptions",
    "vendorFilterOptions",
  ],
  data: function() {
    return {
      searchStr: null,
      availableStatus: ["all", "synced", "not synced"],
      availabilityOptions: ["all", "online store", "unavailable"],
      statusFilter: null,
      availabilityFilter: null,
      selectAllProductsOption: false,
      showMoreFilters: false,
      showSortByOptions: false,
      filterStockOnly: false,
      filterIncludeActive: false,
      filterIncludeDraft: false,
      sortValue: null,
      selectedVendorFilter: "",
      selectedProductTypeFilter: "",
      excludeZeroStock: false,
      searchOptions: [
        { text: "Barcode", value: "barcode" },
        { text: "Product ID", value: "product_id" },
        { text: "SKU", value: "sku" },
        { text: "Tags", value: "tags" },
        { text: "Title", value: "title" },
      ],
      storesWithNewFilters: [
        "blue-bungalow-nz.myshopify.com",
        "dressbarnonline-dev.myshopify.com",
        "lirumlarumleg.myshopify.com",
        "lirumlarumlegno.myshopify.com",
        "pccha-eu.myshopify.com",
        "solado-eu.myshopify.com",
        "260-sample-sale.myshopify.com",
      ],
      valid: false,
    };
  },
  mounted() {
    clearInterval(this.refreshInterval);
    this.refreshTimer = 15;

    if(this.currentShop.type === 'source' && !this.availableStatus.includes('unsupported')) {
      this.availableStatus.push('unsupported')
    } else if(this.currentShop.type === 'destination' && !this.availableStatus.includes('replaced')) {
      this.availableStatus.push('replaced')
    }
  },
  computed: {
    ...mapState("shop", ["currentShop"]),

    ...mapState("product", ["selectProductShopId", "currentShopProducts"]),

    selectedCount() {
      let selectCount = 0;
      this.currentShopProducts.forEach((product) => {
        if (product.isSelected) {
          selectCount++;
        }
      });
      return selectCount;
    },

    hasMultiSelections() {
      return this.selectedCount > 0;
    },

    ...mapFields([
      "activeShop",
      "bulkSync",
      "refreshInterval",
      "refreshTimer",
      "selectedSearchAttribute",
    ]),

    enableNewFilters() {
      return this.storesWithNewFilters.includes(this.currentShop.store_domain);
    },
  },

  methods: {
    getStatusFilterOptions() {
      if(this.currentShop && this.currentShop.type === 'destination') return
    },

    searchProductsByAttribute() {
      this.$refs.searchTextArea.$el.querySelector("input[type='text']").focus();
      if (this.searchStr) {
        this.searchProducts();
      }
    },

    startTimer() {
      if (this.refreshTimer <= 0) {
        this.refreshInterval = clearInterval(this.refreshInterval);
        this.refreshTimer = 15;
        return
      }
      this.refreshTimer -= 1;
    },

    searchProducts(isActionRefresh = false) {
      this.$emit("productsSearched", []);
      this.bulkSync = false;
      if (isActionRefresh) {
        this.statusFilter = this.searchStr = this.availabilityFilter = this.sortValue = null;
        this.selectedVendorFilter = "";
        this.selectedProductTypeFilter = "";
        this.selectedSearchAttribute = "none";
        clearInterval(this.refreshInterval);
        this.refreshInterval = setInterval(this.startTimer, 1000);
      }
      this.$store.commit(`product/${SET_CURRENT_SHOP_PRODUCTS}`, []);
      let sourceStoreId = this.currentShop.id;
      let destinationStoreId = this.selectProductShopId;
      let productFilters = [];
      let sortByParam = null;

      if (this.currentShop.type != "source") {
        sourceStoreId = this.selectProductShopId;
        destinationStoreId = this.currentShop.id;
      }
      if (this.statusFilter != null && this.statusFilter != "all") {
        if (this.statusFilter === "not synced") {
          productFilters.push("unsynced");
        } else {
          productFilters.push(this.statusFilter);
        }
      }
      if (this.filterStockOnly) {
        productFilters.push("has_stock");
      }
      if (this.filterIncludeActive && !this.filterIncludeDraft) {
        productFilters.push("published");
      }
      if (this.filterIncludeDraft && !this.filterIncludeActive) {
        productFilters.push("unpublished");
      }

      if (this.availabilityFilter && this.availabilityFilter !== "all") {
        if (this.availabilityFilter === "online store") {
          productFilters.push("published");
        } else if (this.availabilityFilter === "unavailable") {
          productFilters.push("unpublished");
        } else {
          productFilters.push(this.availabilityFilter);
        }
      }

      if(this.excludeZeroStock) {
        productFilters.push('exclude_zero_stock')
      }

      switch (this.sortValue) {
        case "atoZ":
          sortByParam = {
            sort_by: "title",
            sort_by_desc: false,
          };
          break;
        case "ztoA":
          sortByParam = {
            sort_by: "title",
            sort_by_desc: true,
          };
          break;
        case "newToOld":
          sortByParam = {
            sort_by: "published_at",
            sort_by_desc: true,
          };
          break;
        case "oldToNew":
          sortByParam = {
            sort_by: "published_at",
            sort_by_desc: false,
          };
          break;
        default:
      }

      let metaFields = [];
      if (
        this.selectedProductTypeFilter !== "" &&
        !!this.selectedProductTypeFilter
      ) {
        metaFields.push({
          product_type: encodeURIComponent(this.selectedProductTypeFilter),
        });
      }

      if (this.selectedVendorFilter !== "" && !!this.selectedVendorFilter) {
        metaFields.push({
          vendor: encodeURIComponent(this.selectedVendorFilter),
        });
      }

      let searchAttribute = undefined;

      if (
        this.activeShop.total_product_count > 30000 ||
        this.enableNewFilters
      ) {
        searchAttribute = this.selectedSearchAttribute;
      } else {
        searchAttribute = undefined;
      }

      this.$store
        .dispatch(`product/${FETCH_PRODUCTS_LIST}`, {
          connection_id: this.selectedShop.connection_id,
          destinationId: destinationStoreId,
          filters: productFilters,
          limiter: this.selectedPerPage,
          metaFields: metaFields,
          pageNumber: 1,
          searchStr: this.searchStr,
          sortBy: sortByParam,
          sourceId: sourceStoreId,
          search_attribute: searchAttribute,
          origin: this.currentShop.type,
        })
        .then(() => {
          this.showSortByOptions = false;
          document.activeElement.blur();
          this.$store.dispatch("plan/getCurrentPlan");
        });
    },
    resetSearch() {
      this.searchStr = null;
      this.selectedSearchAttribute = "none";
      this.searchProducts();
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled-cover {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  height: calc(100% - 24px);
  left: 8px;
  position: absolute;
  top: 12px;
  width: calc(100% - 8px);
  z-index: 1;
}
.sort-by-button {
  background: #fff;
  border-color: rgba(158, 158, 158, 1);
  width: 100%;

  &:hover {
    background: #fff !important;
  }
}

.table-control-panel {
  .controls {
    overflow: hidden;
    padding: 15px 18px 20px 18px;
    .sortby-options {
      border: 1px solid rgba(158, 158, 158, 1);
      right: 48px;
      transform: translateY(10px);
    }
    .sortby-options,
    .filter-options {
      position: absolute;
      z-index: 2;
      border-radius: 3px;
    }
  }
}
</style>
