<template>
  <v-container class="product-management-page pt-0">
    <UnsyncConfirmation
      :showDialog="showUnsyncConfirmation"
      :showInnerDialog="isDeleteDialogVisible"
      :showKeepInnerDialog="isKeepDialogVisible"
      :targetProduct="targetProduct"
      :unsyncDetails="unsyncDetails"
      @close-inner-dialog-handler="onCloseInnerDialogHandler"
      @close-outer-dialog-handler="onCloseOuterDialogHandler"
      @on-delete-handler="unsyncAction === 'bulk' ? bulkUnsyncProducts(false) : addUnsyncProductAction(targetProduct, false)"
      @show-delete-confimation-dialog="showDeleteConfirmationDialog"
      @show-keep-confirmation-dialog="showKeepConfirmationDialog"
      @unsync-and-keep="unsyncAction === 'bulk' ? bulkUnsyncProducts(true) : addUnsyncProductAction(targetProduct, true)"
      deleteDialogTitle="Unsync and Delete Products?">
    </UnsyncConfirmation>

    <div v-if="isBulkSyncActive && activeShop && showBulkSyncBanner" class="fixed-banner">
      <v-icon color="#FCB058" class="mr-4 sync-icon">sync</v-icon>
      Syncing in progress, you may close this window.
      <v-btn class="ml-4" fab x-small elevation="0" @click="hideSyncBanner">
        <v-icon color="rgba(0, 0, 0, 0.87)">
          mdi-close
        </v-icon>
      </v-btn>
    </div>

    <div v-if="currentShop === null || isLoading || isLoadingConnectedShops">
      <data-preparation-overlay></data-preparation-overlay>
    </div>

    <PageHeader pageDescription="Sync and manage your inventory">
      <template #title>
        Products
      </template>
    </PageHeader>

    <v-row class="pt-1">
      <v-col cols="auto" class="pr-6">
        <h2 style="transform: translateY(2px)">
          <v-icon block color="rgba(0,0,0,0.87)" style="transform: translateY(-2px)">store</v-icon>
        </h2>
      </v-col>
      <v-col cols="4" class="select-store-dropdown relative px-0">
        <v-autocomplete
          v-model="selectedShopId"
          :items="connectedShops"
          item-value="id"
          item-text="store_domain"
          :label="'Select a '+partnerStoreLabel"
          outlined
          dense
          :menu-props="{ auto: true, offsetY: true }"
          :loading="isLoadingConnectedShops"
          @change="loadProductsByShop()"
          attach=".select-store-dropdown"
          hide-no-data
          clearable
          @click:clear="handleClear"
        ></v-autocomplete>
      </v-col>
      <v-col class="text-right">
        <bulk-smart-mapping-btn v-if="currentShop != null && currentShop.type === 'destination' && selectedShopId != null" :productsSyncedLimit="productsSyncedLimit" :productsSynced="productsSynced" />
      </v-col>
    </v-row>

    <v-card class="card-rounded not-from-top mb-7" :class="{ 'd-none' : !selectedShopId }">
      <v-row class="pt-1 px-3 py-3">
        <v-col cols="12" class="px-0 pt-0">
          <v-data-table
            v-if="selectedShopId != null"
            v-model="selectedProductsList"
            :headers="tableHeaders"
            :items="currentShopProductsWithIndex"
            :loading="(isLoading || currentShop === null)"
            hide-default-footer
            :items-per-page="currentShopProducts.length"
            :show-select="currentShop != null && currentShop.type != 'source'"
            :item-class="itemStatus">

            <template v-slot:top>
              <table-control-panel @productsSearched="productsSearchedHandler" :selectedShop="selectedShop" :selectedPerPage="selectedPerPage" ref="controlPanel" :vendorFilterOptions="vendorFilterOptions" :productTypeFilterOptions="productTypeFilterOptions"></table-control-panel>
              <div class="selection-actions my-4" v-if="!isBulkSyncActive">
                <span v-if="selectedProductsList.length > 0 && !selectAllProducts" class="float-left">
                  {{ selectedProductsList.length }}
                  <span v-if="selectedProductsList.length > 1">products</span>
                  <span v-else>product</span>
                  selected
                </span>
                <span v-else-if="selectedProductsList.length >= 10 && selectAllProducts" class="float-left">
                  Selected all products
                </span>

                <span class="ml-5" v-if="currentShop != null && currentShop.type != 'source' && selectedProductsList.length > 0">
                  <v-btn outlined dense class="mr-2" @click="bulkSyncProducts()" :disabled="selectedProductsSourceIds.length < 1">Sync {{selectedProductsSourceIds.length}} product(s)</v-btn>
                  <v-btn outlined dense @click="showBulkUnsyncDialogHandler('bulk')" :disabled="(selectedProductsList.length - selectedProductsSourceIds.length) === 0">Unsync {{ selectedProductsList.length - selectedProductsSourceIds.length }} product(s)</v-btn>
                </span>
              </div>
              <div class="jobs-in-progress" v-if="isBulkSyncActive && activeShop['bulk-sync-count'] > 0">
                <div class="jobs-in-progress-inner" @click="refreshTable" :class="{ 'block-refresh': refreshTimer < 15 }">
                  <v-icon color="#FD8800" small class="mr-1">sync</v-icon> {{ activeShop['bulk-sync-count'] }} sync in progress &nbsp; |
                  <span class="text-uppercase d-inline-block" style="vertical-align: middle;">
                    <template v-if="refreshTimer >= 15">Click to Refresh</template>
                    <template v-else>click to refresh in {{ refreshTimer }} seconds</template>
                  </span>
                </div>
              </div>
            </template>

            <template v-slot:item.title="{ item }">
              <v-card-text class="product-title-block relative pl-0">
                <v-img width="35" :src="item.default_image_url" class="float-left mr-3"></v-img>
                <span class="product-title-text float-left">
                  {{ item.title }}

                  <!-- Product Replaced -->
                  <div v-if="item.product_status === 'replaced' && currentShop.type === 'destination'">
                    (Replaced by source store.
                    <a href="https://help.syncio.co/en/articles/6958498-what-is-a-replaced-product-and-what-do-i-do-with-it" class="link link-underline" target="_blank">
                      What to do
                    </a>)
                  </div>
                  <!-- Product Replaced -->
                </span>
                <div class="view-detail-btn absolute" @click="openProductDecDialog(item)">
                  View Details <v-icon size="20">chevron_right</v-icon>
                </div>
              </v-card-text>
            </template>

            <template v-slot:item.total_quantity="{ item }">
              <div v-if="isDefaultSourceInventoryAvailable">
                <product-inventory-counter
                  :source-store-id="sourceStore.id"
                  :product-id="item.external_product_id"
                  :location-id="selectedShop.source_default_inventory_location.external_reference_id"
                  :destination-store-id="destinationStore.id"
                  :index="item.index"
                  @inventoryUpdate="onInventoryUpdate"
                  :inventoryItemIndex="inventoryItemIndex" />
              </div>
              <div v-else>
                <v-card-text class="text-center">
                  {{ item.total_inventory_quantity }}
                </v-card-text>
              </div>
            </template>

            <template v-slot:item.status="{ item }">
              <v-card-text class="px-0">
                <template v-if="getProductSyncStatus(item) === 'attention'">
                  <a href="http://help.syncio.co/en/articles/5958687-attention-status-for-product-imports" target="_blank" class="item-status text-capitalize" :class="getProductSyncStatus(item)">
                    <span class="indicator"></span>
                    {{ getProductSyncStatus(item) }}!
                    <v-icon size="18px">mdi-chevron-right</v-icon>
                  </a>
                </template>
                <template v-else-if="item.product_status === 'unsupported' && currentShop.type === 'source'">
                  <a href="https://help.syncio.co/en/articles/6958447-woocommerce-supported-product-types" class="link link-border" target="_blank">
                    Woo product type not supported
                  </a>
                </template>
                <template v-else-if="item.product_status === 'replaced' && currentShop.type === 'source'">
                  <span class="item-status text-capitalize not synced">
                    <span class="indicator"></span>
                    Replaced
                  </span>
                </template>
                <span v-else class="item-status text-capitalize" :class="getProductSyncStatus(item)">
                  <span class="indicator"></span>
                  {{ getProductSyncStatus(item) }}
                </span>
              </v-card-text>
            </template>

            <template v-slot:item.visibility="{ item }">
              <v-card-text class="pl-0" v-if="currentShop != null">
                <span v-if="item.published_at !=  null">
                  Online Store
                </span>
                <span v-else>
                  Unavailable
                </span>
              </v-card-text>
            </template>

            <template v-slot:item.actions="{ item }">
              <div v-if="currentShop != null && currentShop.type != 'source'">
                <div v-if="getProductSyncStatus(item) !== 'pending'">
                  <template v-if="item.mapper_id != null">
                    <v-btn outlined small class="action-btn" @click="openProductMapperDialog(item)">View Sync</v-btn>
                    <v-btn v-if="getProductSyncStatus(item) === 'synced'" outlined small class="action-btn" @click="resyncProduct(item)">Resync</v-btn>
                    <v-btn outlined small class="action-btn" @click="openUnsyncProductDialog(item)">Unsync</v-btn>
                  </template>
                  <template v-else>
                    <v-btn outlined small class="action-btn" @click="openProductMapperDialog(item)">
                      Map
                    </v-btn>
                    <v-btn v-if="!item.product_status" outlined small class="action-btn" @click="addImportProductAction(item)">
                      Sync
                    </v-btn>
                  </template>
                </div>
              </div>
            </template>

          </v-data-table>
          <product-pagination @pageChanged="pageChangedHandler" v-if="selectedShop != null"></product-pagination>
        </v-col>
      </v-row>
    </v-card>

    <product-detail-block v-if="currentViewShopProduct != null"
      :partner-shop-id="this.selectedShopId"
    ></product-detail-block>
    <product-mapper-block v-if="currentViewShopProduct != null"
      :partner-shop-id="this.selectedShopId"
      :enable-mapping="enableCurrentViewProductMapping"
      :productsSynced="productsSynced"
      :productsSyncedLimit="productsSyncedLimit"
    ></product-mapper-block>

    <v-overlay :value="showBulkSyncDialog">
      <v-card elevation="0" class="jobs-in-progress-modal" light width="483">
        <h3 class="mb-4"><v-icon color="#FCB058" class="sync-icon mr-3">sync</v-icon> Jobs are now in progress</h3>
        <p class="mb-4">Refresh <v-icon>refresh</v-icon> the table to check your progress.</p>
        <div class="text-right">
          <v-btn color="primary" @click="closeBulkSyncDialogHandler" height="48px" width="70px" elevation="0">OK</v-btn>
        </div>
      </v-card>
    </v-overlay>

    <v-overlay :value="isLocationPendingModalVisible">
      <v-card class="card-rounded not-a-link inventory-change-dialog pa-8" light width="459px">
        <h3 class="h3 mb-5">
          <v-icon class="mr-3" size="24px" color="#D91E18">error</v-icon>
          Store connection is pending.
        </h3>

        <p>There is no location assigned to <i>{{ sourceStore && sourceStore.store_domain }}.</i> To continue syncing products, please assign a location to this Source Store.</p>

        <div class="d-flex justify-end align-center mt-7">
          <v-btn @click="assignLocationHandler" class="btn-primary ml-6" elevation="0">Assign Location</v-btn>
        </div>
      </v-card>
    </v-overlay>

    <v-overlay :value="isSyncLimitModalVisible" class="dialog dialog-disconnect">
      <v-card light class="card-rounded not-a-link" width="550px" v-click-outside="closeSyncLimitModalHandler">
        <v-toolbar height="71">
          <v-container class="px-0 d-flex justify-space-between">
            <v-card-title class="h3 pa-0">
              Selection exceeds your plan limit
            </v-card-title>
            <v-icon size="30px" color="#ffffff" class="mt-1" @click="closeSyncLimitModalHandler">close</v-icon>
          </v-container>
        </v-toolbar>

        <div class="dialog-body pa-10">
          <h2 class="mb-4" v-if="productsSyncedLimit === productsSynced">
            Maximum products sync limit reached.
          </h2>
          <h2 class="mb-4" v-else>
            You can only sync {{ productsSyncedLimit - productsSynced }} more products.
          </h2>

          <p>Upgrade your syncio plan to sync above your current plan's limit or, Unsync some products to free up your plan limit.</p>

          <div class="d-flex justify-space-between align-center mt-8">
            <router-link v-if="currentShop && currentShop.platform === 'shopify'" tag="button" to="/account/billing" class="upgrade-btn" style="height: 48px; width: 152px; font-weight: bold;">Upgrade</router-link>
            <a v-if="currentShop && currentShop.platform === 'woocommerce'" :href="wooPlanSelectionLink" class="upgrade-btn mt-5" style="height: 48px; line-height: 48px; width: 152px; font-weight: bold;">Upgrade</a>
          </div>
        </div>

      </v-card>
    </v-overlay>

  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { SET_CURRENT_PARTNER_SHOP, SET_SELECTED_PRODUCT_SHOP_ID, SET_CURRENT_SHOP_PRODUCTS, SET_CURRENT_VIEW_SHOP_PRODUCT, SET_SHOW_PRODUCT_DETAIL, SET_SHOW_PRODUCT_MAPPER, SET_IS_LOADING_PRODUCTS_BY_SHOP } from "@/store/mutations.type";
import { ADD_ACTION, ADD_NOTIFICATION, LOAD_CONNECTED_SHOPS, ADD_PRODUCT_ACTION_STATUS, FETCH_PRODUCTS_LIST } from "@/store/actions.type";
import TableControlPanel from "../components/TableControlPanel";
import ProductDetailBlock from "../components/ProductDetailBlock";
import ProductMapperBlock from "../components/ProductMapperBlock";
import ProductApiService from "@/common/api/product.service";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay";
import ProductInventoryCounter from "../components/ProductInventoryCounter";
import ProductPagination from '../components/ProductPagination.vue';
import BulkSmartMappingBtn from '../components/BulkSmartMappingBtn.vue';
import UnsyncConfirmation from "@/components/ConfirmationBox/UnsyncConfirmation.vue";
import { createHelpers } from 'vuex-map-fields';
import JwtService from "@/common/jwt.service";
import PageHeader from "@/views/payouts/components/PageHeader.vue";

const { mapFields } = createHelpers({
  getterType: 'product/getField',
  mutationType: 'product/updateField'
});

export default {
  name: "ProductManagementPage",

  data: function() {
    return {
      tableHeaders: [
        { text: "Product", value: "title", sortable: false, width: "40%" },
        { text: "Inventory", value: "total_quantity", sortable: false, width: "4%" },
        { text: "Status", value: "status", sortable: false, width: "12%", align: 'center' },
        { text: "Sales Channel Visibility", value: "visibility", sortable: false, width: "18%" },
        { text: "Actions", value: "actions", sortable: false, width: "26%", align: 'right' },
      ],
      enableCurrentViewProductMapping: false,
      inventoryItemIndex: 0,
      isUnsyncOptionsDialogOpened: false,
      searchStr: null,
      selectAllProducts: false,
      selectedProductsList: [],
      selectedShopId: null,
      showUnsyncConfirmation: false,
      targetProduct: null,
      unsyncAction: '',
      isDeleteDialogVisible: false,
      isKeepDialogVisible: false,
      selectedProductsSourceIds: [],
      showBulkSyncDialog: false,
      localStorage: {},
      isLocationPendingModalVisible: false,
      showBulkSyncBanner: false
    };
  },

  props: ["productPageEntryShopId"],

  components: {
    TableControlPanel,
    ProductDetailBlock,
    DataPreparationOverlay,
    ProductMapperBlock,
    ProductInventoryCounter,
    ProductPagination,
    BulkSmartMappingBtn,
    UnsyncConfirmation,
    PageHeader,
  },

  computed: {
    ...mapFields([
      'activeShop',
      'bulkSync',
      'isSyncLimitModalVisible',
      'refreshTimer',
      'selectedPerPage',
      'selectedSearchAttribute',
    ]),

    ...mapState("shop", ["connectedShops", "currentShop", "isLoadingConnectedShops"]),
    ...mapState("product", ["showProductMapper", "isLoadingProductsByShop", "currentShopProducts", "currentViewShopProduct", "productActionStatusMapper", "selectProductShopId", "productMetaOptions"]),
    ...mapState("processQueue", ["processingQueue"]),
    ...mapState("shop_2_0", ["isMultiLocationEnabled"]),
    ...mapState("plan", ["currentActivePlan"]),

    wooPlanSelectionLink() {
      return `${ process.env.VUE_APP_WOO_BILLING}/?jwt=${JwtService.getToken() }`
    },

    partnerStoreLabel() {
      if (this.currentShop != null && this.currentShop.type != "source") {
        return "Source store";
      } else {
        return "Destination store";
      }
    },

    isLoading() {
      return this.isLoadingProductsByShop || this.isLoadingConnectedShops;
    },

    selectedShop() {
      let selectedShop = this.connectedShops.find((shop) => {
        return shop.id === this.selectedShopId;
      });
      return selectedShop;
    },

    sourceStore() {
      if (this.currentShop != null && this.currentShop.type != "source") {
        return this.selectedShop;
      } else {
        return this.currentShop;
      }
    },

    destinationStore() {
      if (this.currentShop != null && this.currentShop.type != "source") {
        return this.currentShop;
      } else {
        return this.selectedShop;
      }
    },

    currentShopProductsWithIndex() {
      return this.currentShopProducts.map((item, index) => ({
        ...item,
        index: +index + 1
      }))
    },

    unsyncDetails() {
      if(this.selectedProductsList.length > 0 && this.unsyncAction === 'bulk') {
        return {
          title: `Select an unsync option for ${this.selectedProductsList.length - this.selectedProductsSourceIds.length} products:`,
          deleteMessage: `You're about to unsync and permanently delete ${this.selectedProductsList.length - this.selectedProductsSourceIds.length} products. You cannot undo this action.`,
          buttonText: `Delete ${this.selectedProductsList.length - this.selectedProductsSourceIds.length} products`
        }
      } else {
        return {
          title: 'Select an unsync option:',
          deleteMessage: `You're about to unsync and permanently delete <span>${this.targetProduct && this.targetProduct.title}</span>. You cannot undo this action.`,
          buttonText: `Delete and unsync`
        }
      }
    },

    isBulkSyncActive() {
      return this.bulkSync || this.activeShop.bulk_sync;
    },

    isDefaultSourceInventoryAvailable() {
      return this.selectedShop?.source_default_inventory_location !== null && Object.keys(this.selectedShop.source_default_inventory_location).length > 0;
    },

    productTypeFilterOptions() {
      let options = [];
      Object.keys(this.productMetaOptions).filter(key => {
        if(key === 'product_type') {
          this.productMetaOptions[key].forEach(option => {
            if(option.value) {
              options.push(option);
            }
          })
        }
      })

      options.sort((a, b) => {
        if (a.value.toLowerCase() < b.value.toLowerCase())
            return -1;
        if (a.value.toLowerCase() > b.value.toLowerCase())
            return 1;
        return 0;
      });

      options.unshift('All');
      return options;
    },

    vendorFilterOptions() {
      let options = [];
      Object.keys(this.productMetaOptions).filter(key => {
        if(key === 'vendor') {
          this.productMetaOptions[key].forEach(option => {
            if(option.value) {
              options.push(option);
            }
          })
        }
      })

      options.sort((a, b) => {
        if (a.value.toLowerCase() < b.value.toLowerCase())
            return -1;
        if (a.value.toLowerCase() > b.value.toLowerCase())
            return 1;
        return 0;
      });

      options.unshift('All');
      return options;
    },

    productsSynced() {
      return this.currentActivePlan && this.currentActivePlan.product_mappers_count;
    },

    productsSyncedLimit() {
      return this.currentActivePlan && this.currentActivePlan.syncio_plan?.sync_product_limit;
    }
  },

  created() {
    this.$store.commit(`product/${SET_CURRENT_SHOP_PRODUCTS}`, []);
    if (this.productPageEntryShopId != null) {
      this.$store.dispatch(`shop/${LOAD_CONNECTED_SHOPS}`, { searchStr: null }).then(() => {
        // We need this in case the page on specific shop's products are requested from another page
        this.selectedShopId = this.productPageEntryShopId;
        this.loadProductsByShop();
      });
    } else if (this.currentShop === null) {
      this.$store.dispatch(`shop/init`);
    }

    this.localStorage = window.localStorage;
  },

  watch: {
    'processingQueue'(queue) {
      let queuedProducts = queue.map(item => {
        return item.actionParam.product.id;
      });

      this.selectedProductsList = this.selectedProductsList.filter(item => {
        return queuedProducts.includes(item.id);
      });
    },

    'selectedProductsList'() {
      this.selectedProductsSourceIds = this.selectedProductsList.filter(product => {
        if(product.mapper_id !== null) { return false }
        return true
      }).map(product => {
        return product.external_product_id;
      });
    },

    'activeShop'() {
      if(localStorage[`selectedProducts_${this.currentShop.id}`]) {
        const productsInLocalStorage = localStorage[`selectedProducts_${this.currentShop.id}`].split(',');

        if(this.activeShop.products && !this.isBulkSyncActive) {
          this.selectedProductsSourceIds = (this.activeShop.products).filter(product => {
            if(productsInLocalStorage.includes(product.external_product_id)) {
              if(product.mapper_id === null) {
                return true;
              }

              return false;
            }
          }).map(product => {
            return product.external_product_id;
          });
        }
      }
    }
  },

  beforeDestroy () {
    this.inventoryItemIndex = 0;
  },

  beforeRouteLeave (to, from, next) {
    this.bulkSync = false;
    this.activeShop = {}
    this.isSyncLimitModalVisible = false;
    this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
    this.$store.commit(`product/${SET_SHOW_PRODUCT_MAPPER}`, false);
    this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, null);
    this.$store.commit(`product/${SET_CURRENT_PARTNER_SHOP}`, null);
    this.resetRefreshAction(15);
    next();
  },

  methods: {
    resetRefreshAction(time) {
      clearInterval(this.refreshInterval);
      this.refreshTimer = time;
    },

    closeBulkSyncDialogHandler() {
      this.showBulkSyncDialog = false;
      this.showBulkSyncBanner = true;
      this.$store.dispatch(`product/${FETCH_PRODUCTS_LIST}`, {
        sourceId: this.sourceStore.id,
        destinationId: this.destinationStore.id,
        searchStr: this.searchStr,
        pageNumber: 1,
        limiter: this.selectedPerPage,
        connection_id: this.selectedShop.connection_id,
        search_attribute: this.selectedSearchAttribute,
        origin: this.currentShop.type
      });
    },

    showBulkUnsyncDialogHandler(unsyncAction) {
      this.showUnsyncConfirmation = true;
      this.unsyncAction = unsyncAction;
    },

    showDeleteConfirmationDialog() {
      this.isDeleteDialogVisible = true;
    },

    showKeepConfirmationDialog() {
      this.isKeepDialogVisible = true;
    },

    onCloseOuterDialogHandler(payload) {
      this.showUnsyncConfirmation = payload;
    },

    onCloseInnerDialogHandler(payload) {
      this.isDeleteDialogVisible = payload;
      this.isKeepDialogVisible = payload;
    },

    itemStatus(item) {
      return this.getProductStatus(item);
    },

    onInventoryUpdate(value) {
      this.inventoryItemIndex = value;
    },

    async loadProductsByShop() {
      this.selectedProductsList = [];
      this.$store.commit(`product/${SET_SELECTED_PRODUCT_SHOP_ID}`, this.selectedShopId);
      this.$store.commit(`product/${SET_CURRENT_SHOP_PRODUCTS}`, []);

      if(this.sourceStore.status === 'pending' && this.isMultiLocationEnabled) {
        this.isLocationPendingModalVisible = true;
        return;
      }

      this.selectedSearchAttribute = "none";

      await this.$store.dispatch(`product/${FETCH_PRODUCTS_LIST}`, {
        sourceId: this.sourceStore.id,
        destinationId: this.destinationStore.id,
        searchStr: this.searchStr,
        pageNumber: 1,
        limiter: this.selectedPerPage,
        connection_id: this.selectedShop.connection_id,
        search_attribute: this.selectedSearchAttribute,
        origin: this.currentShop.type
      });

      if(this.currentShop.type === 'destination') {
        this.$store.dispatch(`product/fetchProductMetaOptions`, this.selectProductShopId);
      } else if(this.currentShop.type === 'source') {
        this.$store.dispatch(`product/fetchProductMetaOptions`, this.currentShop.id);
      }
    },

    assignLocationHandler() {
      this.$router.replace({
        name: "ShopManagementPage"
      });
    },

    openProductDecDialog(product) {
      // DO it here because some of the mandatory params is in this component
      this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, true);
      this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, null);
      return new Promise((resolve) => {
        ProductApiService.loadProductOnShop(this.sourceStore.id, this.destinationStore.id, product.external_product_id).then(({ data }) => {
          if (data.success) {
            this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, data.sourceProduct);
            this.$store.commit(`product/${SET_SHOW_PRODUCT_DETAIL}`, true);
          }
          this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
          resolve();
          document.querySelector('.intercom-lightweight-app, #intercom-container').style.display = 'none';
        }).catch((error) => {
          this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
        });
      });
    },

    openProductMapperDialog(product) {
      this.enableCurrentViewProductMapping = true;
      if (product.mapper_id != null) {
        this.enableCurrentViewProductMapping = false;
      }
      this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, true);
      this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, null);
      return new Promise((resolve) => {
        ProductApiService.loadProductOnShop(this.sourceStore.id, this.destinationStore.id, product.external_product_id).then(({ data }) => {
          if (data.success) {
            this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, data);
            this.$store.commit(`product/${SET_SHOW_PRODUCT_MAPPER}`, true);
          }
          resolve();
          this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
          document.querySelector('.intercom-lightweight-app, #intercom-container').style.display = 'none';
        }).catch((error) => {
          this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
        });
      });
    },

    openUnsyncProductDialog (product) {
      this.targetProduct = product;
      this.unsyncAction = "single";
      this.showUnsyncConfirmation = true;
    },

    addImportProductAction(productObj) {
      let productsAvailableToSync = +this.productsSyncedLimit - +this.productsSynced;

      if(productsAvailableToSync === 0) {
        this.isSyncLimitModalVisible = true;
        return;
      }

      if (typeof(this.selectedShop) === 'undefined') {
        return;
      }
      this.$store.dispatch(`product/${ADD_PRODUCT_ACTION_STATUS}`, {
        connectionId: this.selectedShop.connection_id,
        productId: productObj.id,
        statusValue: 'Pending',
        message: 'product pending for import',
      });

      //  yes we found it
      let connectionId = this.selectedShop.connection_id;
      this.$store.dispatch(`processQueue/${ADD_ACTION}`, {
        actionType: 'product_import',
        param: {
          connection_id: connectionId,
          product: productObj,
          productStatusKey: this.selectedShop.connection_id + ':' + productObj.id,
        }
      });
    },

    addUnsyncProductAction(productObj, keepProduct) {
      if (typeof(this.selectedShop) === 'undefined') {
        return;
      }
      this.$store.dispatch(`product/${ADD_PRODUCT_ACTION_STATUS}`, {
        connectionId: this.selectedShop.connection_id,
        productId: productObj.id,
        statusValue: 'Pending',
        message: 'product pending for unsync',
      });
      this.$store.dispatch(`processQueue/${ADD_ACTION}`, {
        actionType: 'product_unsync',
        param: {
          keepProduct: keepProduct,
          product: productObj,
          productStatusKey: this.selectedShop.connection_id + ':' + productObj.id,
        }
      });
      this.targetProduct = null;
      this.showUnsyncConfirmation = false;
      this.isDeleteDialogVisible = false;
      this.isKeepDialogVisible = false;
    },

    async bulkSyncProducts() {
      let productsAvailableToSync = +this.productsSyncedLimit - +this.productsSynced;
      let selectedProductsToSync = this.selectedProductsSourceIds.length;

      if(selectedProductsToSync > productsAvailableToSync) {
        this.isSyncLimitModalVisible = true;
        return;
      }

      if(this.selectedProductsSourceIds.length) {
        this.bulkSync = true;
        this.showBulkSyncDialog = true;
        localStorage.setItem(`selectedProducts_${this.currentShop.id}`, this.selectedProductsSourceIds);
        const response = await ProductApiService.syncAllProducts(this.selectedShop.connection_id, this.selectedProductsSourceIds);
        this.$store.dispatch('plan/getCurrentPlan');
        return;
      }
      if (this.selectAllProducts) {
        return new Promise((resolve) => {
          ProductApiService.syncAllProducts(this.selectedShop.connection_id).then(({ data }) => {
            if (data.success) {
              this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                notification: {
                  id: 'N' + (Math.floor(Math.random() * 100000000)),
                  position: "bottom-left",
                  type: "info",
                  body: "All products sync task has been queued. Please come back and check it later.",
                  state: 0,
                  length: 6000, // seconds
                  data: null,
                },
              }, {root:true});
            }
            resolve();
          }).catch((error) => {});
        });
      } else {
        this.selectedProductsList.forEach((product) => {
          if (product.mapper_id === null) {
            this.addImportProductAction(product);
          }
        });
      }
    },

    bulkUnsyncProducts(shouldKeepProduct) {
      this.unsyncAction = "bulk";
      if (this.selectAllProducts) {
        return new Promise((resolve) => {
          ProductApiService.unsyncAllProducts(this.selectedShop.connection_id, shouldKeepProduct).then(({ data }) => {
            if (data.success) {
              this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                notification: {
                  id: 'N' + (Math.floor(Math.random() * 100000000)),
                  position: "bottom-left",
                  type: "info",
                  body: "All products unsync task has been queued. Please come back and check it later.",
                  state: 0,
                  length: 6000, // seconds
                  data: null,
                },
              }, {root:true});
            }
            this.showUnsyncConfirmation = false;
            this.isDeleteDialogVisible = false;
            this.isKeepDialogVisible = false;
            resolve();
          }).catch((error) => {
            this.showUnsyncConfirmation = false;
            this.isDeleteDialogVisible = false;
            this.isKeepDialogVisible = false;
          });
        });
      } else {
        this.selectedProductsList.forEach((product) => {
          if (product.mapper_id != null) {
            this.$store.dispatch(`product/${ADD_PRODUCT_ACTION_STATUS}`, {
              connectionId: this.selectedShop.connection_id,
              productId: product.id,
              statusValue: 'Pending',
              message: 'product pending for unsync',
            });
            this.addUnsyncProductAction(product, shouldKeepProduct);
          }
        });
        this.showUnsyncConfirmation = false;
        this.isDeleteDialogVisible = false;
        this.isKeepDialogVisible = false;
      }
    },

    getProductStatus(product) {
      let status = null;
      if (this.selectedShop != null) {
        let identifier = this.selectedShop.connection_id + ':' +product.id;
        if(!this.bulkSync) {
          this.productActionStatusMapper.forEach((item) => {
            if (item.key === identifier) {
              status = item.status;
            }
          });
        }
      }
      return status;
    },

    getProductSyncStatus(product) {
      let status = 'un-synced';

      const { product_status, mapper_id, is_sync_failed, external_product_id } = product

      if(product_status === 'replaced' && mapper_id) {
        status = 'replaced';
      }

      else if(mapper_id) {
        status = 'synced';
      }

      else if(is_sync_failed && !mapper_id) {
        status = 'attention';
      }

      else if(!mapper_id && !is_sync_failed) {
        status = 'not synced';
      }

      if(!mapper_id && this.isBulkSyncActive && this.localStorage[`selectedProducts_${this.currentShop.id}`] && this.localStorage[`selectedProducts_${this.currentShop.id}`].includes(external_product_id)) {
        status = 'pending';
      }

      if(this.getProductStatus(product) === 'Pending') {
        status = 'pending';
      }

      return status;
    },

    resyncProduct(product) {
      this.$store.dispatch(`processQueue/${ADD_ACTION}`, {
        actionType: 'product_resync',
        param: {
          mapperId: product.mapper_id,
          product: product,
          productStatusKey: this.selectedShop.connection_id + ':' + product.id,
        }
      });
    },

    pageChangedHandler(payload) {
      this.selectedProductsList = payload;
    },

    productsSearchedHandler(payload) {
      this.selectedProductsList = payload;
    },

    handleClear() {
      this.activeShop = {};
    },

    refreshTable() {
      this.$refs.controlPanel.searchProducts(true);
      this.$store.dispatch('plan/getCurrentPlan');
    },

    hideSyncBanner() {
      this.showBulkSyncBanner = false;
    },

    closeSyncLimitModalHandler() {
      this.isSyncLimitModalVisible = false;
    }
  }
}
</script>

<style lang="scss">
.product-management-page {

  .card-rounded {
    border-radius: 20px !important;
    border: 0;

    &.not-from-top {
      box-shadow: 0px 6px 6px #00000029 !important;
    }
  }

  .v-data-table {
    border: 0;
    box-shadow: none !important;
    border-radius: 0;
  }

  tr {
    &:hover {
      background: #F8F9FA !important;

      .view-detail-btn {
        opacity: 1;
        visibility: visible;
        font-size: 12px;
        color: #111;
        border: 1px solid #111;
        top: 50%;
        transform: translateY(-50%);
        height: auto;
        padding: 0 8px 0 11px;
        background: #fff;
      }
    }

    &.Pending {
      .v-simple-checkbox {
        opacity: .25;
        pointer-events: none;
      }
    }
  }

  th {
    background: #F6F6F7;
    border-bottom-color: #D6D6D6 !important;
    color: #0E3B4D !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }

  .v-card__text {
    font-size: 12px;
  }
  .product-title-block {
    overflow: hidden;
    > .product-title-text,
    > div {
      line-height: 35px;
    }
    .view-detail-btn {
      align-items: center;
      background: #F8F9FA;
      color: #727272;
      display: flex;
      height: 100%;
      opacity: 0;
      padding: 0 20px;
      right: 0px;
      top: 0;
      visibility: hidden;
      transition: opacity .35s;
      cursor: pointer;

      i {
        color: #495057;
      }
    }
  }
  .success-status-chip {
    color: #43A047;
  }
  .status-circle {
    font-size: 12px;
    margin-right: 10px;
  }
  .v-input {
    .v-input__slot {
      background-color: #fff;
    }
  }
  .action-btn {
    border-radius: 2px;
    padding: 10px !important;
    width: 70px !important;

    + .action-btn {
      margin-left: 7px;
    }
  }
  .unsync-options-dialog {
    .title-text {
        line-height: 18px;
    }
    .divline {
        border-bottom: 1px solid #ccc;
        margin: auto 15px;
    }
    .dc-close {
        position: absolute;
        right: 15px;
    }
  }
  .selection-actions {
    &:empty {
      display: none;
    }
    overflow: hidden;
    font-weight: 700;
    padding: 0px 20px;
    > span {
      line-height: 35px;
      margin-right: 10px;
    }
    .select-all-checkbox-block {
      overflow: hidden;
      .all-product-checkbox {
        padding-top: 4px;
      }
    }
  }
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

.select-store-dropdown {
  .v-menu__content {
    min-width: 0 !important;
    max-width: 100% !important;
    width:100% !important;
    top: 55px !important;
    left: 0 !important;
    overflow-y: scroll !important;

    .v-list  {
      padding: 0 !important
    }

    .v-list-item__title {
      font-size: 14px !important;
    }
  }
}

.jobs-in-progress-modal {
  border-radius: 20px !important;
  padding: 32px 36px;
  background: #fff !important;

  p {
    color: #161616;
  }

  h3 {
    font-weight: normal;
  }

  .v-btn {
    border-radius: 4px !important;
  }

  .sync-icon {
    animation: rotating 2s linear infinite;
  }

  i:not(.sync-icon) {
    margin: 0 2px;
    position: relative;
    transform: translateY(-1px);
  }
}

.fixed-banner {
  align-items: center;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0,0,0,.25);
  display: flex;
  font-size: 18px;
  height: 59px;
  left: 50%;
  min-width: 316px;
  padding: 0 24px;
  position: fixed;
  top: 8px;
  transform: translateX(-50%);
  z-index: 5;

  .sync-icon {
    animation: rotating 2s linear infinite;
  }
}

@-webkit-keyframes rotating {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

.failed-status-chip {
  background: #FFECEC !important;
  color: #D91E18 !important;
}

.jobs-in-progress {
  padding: 0 20px;

  &-inner {
    align-items: center;
    background: #FFF2E3;
    border-radius: 30px;
    color: #FD8800;
    cursor: pointer;
    display: flex;
    display: inline-block !important;
    font-size: 14px;
    height: 24px;
    line-height: 23px;
    margin: 1rem 0;
    padding: 0 16px;

    span {
      font-weight: bold;
    }

    i {
      animation: rotating 2s linear infinite;
    }

    .v-icon {
      position: relative;
      top: -1px;
    }
  }
}

.item-status {
  background: #DDE2E5;
  border-radius: 20px;
  color: #212429;
  display: inline-block;
  font-size: 11px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-align: center;
  text-decoration: none;

  .indicator {
    background: #495057;
    border-radius: 20px;
    display: inline-block;
    height: 8px;
    margin-right: 3px;
    width: 8px;
  }

  &.synced:not(.not) {
    background: #ECFFEC;
    color: #085F07;

    .indicator {
      background: #28A228;
    }
  }

  &.failed {
    background: #FFECEC;
    color: #D91E18;

    .indicator {
      background: #FF0000;
    }
  }

  &.attention {
    background: #ffe161;
    color: #754116;
    font-weight: bold;
    transition: .35s;

    .indicator {
      background: #754116;
    }

    .v-icon {
      transform: translateY(-1px) translateX(-1px);
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  &.pending {
    background: #FFF2E3;
    color: #FD8800;

    .indicator {
      background: #FD8800;
    }
  }
}

.block-refresh {
  pointer-events: none;
}
</style>
