<template>
  <v-overlay :value="showDialog" class="dialog dialog-disconnect">
    <v-card light class="card-rounded not-a-link" width="725px" v-click-outside="closeOuterDialogHandler">
      <v-toolbar height="71">
        <v-container class="px-0 d-flex justify-space-between">
          <v-card-title class="h3 pa-0">
            {{ unsyncDetails.title }}
          </v-card-title>
          <v-icon size="30px" color="#ffffff" class="mt-1" @click="closeOuterDialogHandler">close</v-icon>
        </v-container>
      </v-toolbar>

      <div class="d-flex justify-space-between dialog-body pa-10">
        <Card
          actionText="Unsync and Keep"
          bodyText="Unsync without deleting the product from your destination store."
          btnClass="btn-primary"
          :eventName="isReplaced ? `show-keep-confirmation-dialog` :`unsync-and-keep`"
          icon="link_off"
          title="Unsync and Keep"
          v-on="$listeners">
        </Card>

        <Card
          actionText="Unsync and Delete"
          bodyText="Unsync and delete the product from your destination store."
          btnClass="btn-danger"
          eventName="show-delete-confimation-dialog"
          icon="delete_forever"
          title="Unsync and Delete"
          v-on="$listeners">
        </Card>
      </div>

      <!-- Only visible for replaced products -->
      <v-overlay :value="showKeepInnerDialog" class="dialog-inner">
        <v-card light class="card-rounded not-a-link" width="460px" v-click-outside="closeInnerDialogHandler">
          <div class="dialog-body px-9 py-7">
            <h4 v-if="isReplaced" class="h3 text-center pb-2">
              <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 6.98337L31.55 28.6667H6.44996L19 6.98337ZM19 0.333374L0.666626 32H37.3333L19 0.333374ZM20.6666 23.6667H17.3333V27H20.6666V23.6667ZM20.6666 13.6667H17.3333V20.3334H20.6666V13.6667Z" fill="#FFC400"/>
              </svg>
              <div class="mt-4">
                This product will be removed <br> from Syncio
              </div>
            </h4>

            <p class="body-text mt-3 mb-0 text-center">Since this product has been replaced, it will <br> no longer show in Syncio after you unsync</p>

            <aside class="d-flex justify-end align-center pt-10">
              <v-btn class="btn-primary mr-6" height="48px" elevation="0" @click="keepHandler">
                Unsync and keep
              </v-btn>
              <span class="btn-dismiss" @click="closeInnerDialogHandler">Cancel</span>
            </aside>
          </div>
        </v-card>
      </v-overlay>

      <v-overlay :value="showInnerDialog" class="dialog-inner">
        <v-card light class="card-rounded not-a-link" width="460px" v-click-outside="closeInnerDialogHandler">
          <div class="dialog-body px-9 py-7">
            <h3 class="h3 d-flex" v-if="!isReplaced">
              <v-icon class="mr-5" size="22px" color="#D91E18">error</v-icon>
              {{ deleteDialogTitle }}
            </h3>
            <h4 v-if="isReplaced" class="h3 text-center pb-2">
              <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 6.98337L31.55 28.6667H6.44996L19 6.98337ZM19 0.333374L0.666626 32H37.3333L19 0.333374ZM20.6666 23.6667H17.3333V27H20.6666V23.6667ZM20.6666 13.6667H17.3333V20.3334H20.6666V13.6667Z" fill="#FFC400"/>
              </svg>
              <div class="mt-4">
                This product will be removed from Syncio and deleted from your Destination store
              </div>
            </h4>
            <p class="body-text mt-3 mb-0 text-center" v-html="unsyncDetails.deleteMessage"></p>

            <aside class="d-flex justify-end align-center pt-10">
              <v-btn class="btn-danger mr-6" height="48px" elevation="0" @click="deleteHandler">
                {{ unsyncDetails.buttonText }}
              </v-btn>
              <span class="btn-dismiss" @click="closeInnerDialogHandler">Cancel</span>
            </aside>
          </div>

        </v-card>
      </v-overlay>

    </v-card>
  </v-overlay>
</template>

<script>
import Card from './Card.vue';

export default {
  name: 'confirmation-box',

  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    showInnerDialog: {
      type: Boolean,
      required: true
    },
    showKeepInnerDialog: {
      type: Boolean,
      required: true
    },
    deleteDialogTitle: {
      type: String,
      required: false,
    },
    unsyncDetails: {
      type: Object,
      required: true
    },
    targetProduct: {
      type: Object,
      required: false
    }
  },

  computed: {
    isReplaced() {
      return this.targetProduct && this.targetProduct.product_status === 'replaced'
    }
  },

  components: {
    Card
  },

  methods: {
    deleteHandler() {
      this.$emit('on-delete-handler');
    },

    keepHandler() {
      this.$emit('unsync-and-keep');
    },

    closeOuterDialogHandler() {
      this.$emit('close-outer-dialog-handler', false);
    },

    closeInnerDialogHandler() {
      this.$emit('close-inner-dialog-handler', false);
    }
  }
}
</script>
